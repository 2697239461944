import { create } from 'zustand';
import axiosInstance from '../auth/axiosInstance';

export const useGetNexudusCountries = create((set) => ({
  countryData: '',
  isCountryDataFetched: false,
  countryDataErrorMessage: '',

  getCountry: (id) => {
    set((state) => ({
      ...state,
      countryData: '',
      isCountryDataFetched: true,
      categoryDataErrorMessage: '',
    }));

    axiosInstance
      .get(`${process.env.REACT_APP_API_BASE_URL}/nexudus-admin/countries/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      })
      .then((res) => {
        set((state) => ({
          ...state,
          countryData: res.data,
          isCountryDataFetched: false,
          countryDataErrorMessage: '',
        }));
      })
      .catch((error) => {
        console.error(error.message);
        set((state) => ({
          ...state,
          countryData: '',
          isCountryDataFetched: false,
          countryDataErrorMessage: error.message,
        }));
      });
  },
}));
