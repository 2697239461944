import axios from 'axios';
import { create } from 'zustand';
import { useEffect } from 'react';

export const tokenStore = create((set) => ({
  sessionToken: localStorage.getItem('sessionToken') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
  isAuth: localStorage.getItem('isAuth') === 'true' || false,
  dataErrorMessage: '',

  resetAuthValues: () => {
    set({
      sessionToken: '',
      refreshToken: '',
      isAuth: false,
      dataErrorMessage: '',
    });
  },

  setAccesToken: (value) => {
    set({ sessionToken: value });
  },

  setIsAuth: (value) => {
    set({ isAuth: value });
  },

  getToken: async (body) => {
    set({
      sessionToken: '',
      refreshToken: '',
      isAuth: false,
      dataErrorMessage: '',
    });

    try {
      const response = await axios.post(
        'https://sso.staging.tcenter.cloud/v1/auth/login',
        body,
        {
          headers: {
            'x-public-key': '2817d1b3-d059-4cef-9b0c-4d40931871b5',
          },
        }
      );

      set({
        sessionToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        isAuth: true,
        dataErrorMessage: '',
      });
    } catch (error) {
      set({
        sessionToken: '',
        isAuth: false,
        dataErrorMessage: error?.message,
      });
    }
  },
}));

export const useGetToken = () => {
  const {
    sessionToken,
    isAuth,
    refreshToken,
    resetAuthValues,
    setAccesToken,
    setIsAuth,
    getToken,
  } = tokenStore((state) => state);

  useEffect(() => {
    localStorage.setItem('sessionToken', sessionToken);
    localStorage.setItem('isAuth', JSON.stringify(isAuth));
    localStorage.setItem('refreshToken', refreshToken);
  }, [sessionToken, isAuth, refreshToken]);

  return {
    sessionToken,
    isAuth,
    refreshToken,
    resetAuthValues,
    setAccesToken,
    setIsAuth,
    getToken,
  };
};

export const { setAccesToken } = tokenStore.getState();
